@import "../../_globalColor";

.podcast-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px;
}

.podcast {
  width: 1000px;
}

.podcast-main-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(px, 1fr));
  gap: 1rem 1rem;
}
.subTitle {
  color: $subTitle;
}

@media (max-width: 768px) {
  .podcast-header-title {
    font-size: 30px;
    text-align: center;
  }
  .podcast-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .podcast {
    width: 300px;
  }
  .podcast-main-div {
    text-align: center;
  }
}
